<i18n>
  {
    "de": {
      "title": "Emissionen",
      "scope12Emissions": "Scope 1–2 Emissionen",
      "total": "Absolut",
      "intensity": "Spezifisch",
      "scope": {
        "s12e": "Scope 1-2",
        "s123e": "Scope 1-3",
        "s1e": "Scope 1",
        "s2e": "Scope 2",
        "s3e": "Scope 3"
      }
    }
  }
  </i18n>

<template>
  <KpiOverviewCard
    class="c-emissions-kpi-overview"
    :title="$t('title')"
    titleIcon="cards/emissions"
    @details-click="onModalOpen()"
  >
    <template #values>
      <KpiBox
        :label="$t('scope12Emissions')"
        :value="formatNumberWithThreshold(tableData[0]?.total, 2, 1, 1)"
        valueUnit="t CO₂e"
        :relativeValue="formatNumberWithThreshold(tableData[0]?.intensity, 2, 1, 1)"
        relativeValueUnit="kg CO₂e/m²"
      />
    </template>

    <template #chart>
      <DoughnutChart
        v-if="tableData[0]?.total > 0"
        :chart-data="processedChartData"
        :options="chartOptions"
        :expandable="false"
        class="emissions-doughnut-chart"
      />
    </template>

    <template #table>
      <DetailList has-header>
        <!-- Headers -->
        <template #header>
          <div class="name" />
          <HeaderEntry :title="$t('total')" unit="t CO₂e" align-right />
          <HeaderEntry :title="$t('intensity')" unit="kg CO₂e/m²" align-right />
        </template>

        <!-- Rows, data -->
        <template>
          <li
            v-for="item in tableData"
            :key="item.indicator"
            :class="{ bold: ['s12e', 's123e'].includes(item.indicator) }"
          >
            <div class="name">
              <span v-if="['s1e', 's2e', 's3e'].includes(item.indicator)" class="tab" />
              <span class="indicator">
                {{ $t(`scope.${item.indicator}`) }}
              </span>
            </div>
            <div class="align-right">
              <span>
                {{ formatNumberWithThreshold(item.total, 2, 1, 1) }}
              </span>
            </div>
            <div class="align-right">
              <span>
                {{ formatNumberWithThreshold(item.intensity, 2, 1, 1) }}
              </span>
            </div>
          </li>
        </template>
      </DetailList>
    </template>

    <template #modal>
      <EmissionsKpiModal
        v-if="modalOpen"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
        :kpi-data="tableData"
        @close="onModalClose()"
      />
    </template>
  </KpiOverviewCard>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import KpiOverviewCard from '@/components/shared/kpi/KpiOverviewCard.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import KpiBox from '@/components/shared/kpi/KpiBox.vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import EmissionsKpiModal from '@/components/building/kpi/EmissionsKpiModal.vue'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    KpiBox,
    DoughnutChart,
    KpiOverviewCard,
    DetailList,
    HeaderEntry,
    EmissionsKpiModal,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modalOpen: false,
    }
  },

  computed: {
    ...mapState('portfolio', ['emissionsSustainabilityIndicatorIdentifiers']),

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        scales: {
          xAxis: {
            display: false,
          },
          yAxis: {
            display: false,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              font: {
                size: 12,
                weight: '500',
                lineHeight: 1,
              },
              boxWidth: 20,
              padding: 5,
            },
          },
          tooltip: {
            callbacks: {
              label: (item) => this.getTooltipLabel(item, { precision: 1, unit: 't CO₂e', label: item.label }),
            },
          },
        },
      }
    },

    processedChartData() {
      return {
        labels: [this.$t('scope.s1e'), this.$t('scope.s2e')],
        datasets: [
          {
            data: [
              this.tableData.find((kpi) => kpi.indicator === 's1e').total,
              this.tableData.find((kpi) => kpi.indicator === 's2e').total,
            ],
            hoverOffset: 4,
            backgroundColor: [colorPalettes.scopeColors.s1e, colorPalettes.scopeColors.s2e],
          },
        ],
      }
    },

    tableData() {
      return _.map(this.emissionsSustainabilityIndicatorIdentifiers, (indicator) => {
        const value = this.building.kpi?.[indicator]
        const hasValue = value !== undefined && value !== null
        const totalValue = hasValue ? value / 1000 : null
        const intensityValue = totalValue !== null ? value / this.building.kpi.energy_area : null

        return {
          indicator,
          total: totalValue || 0,
          intensity: intensityValue || 0,
        }
      })
    },
  },

  methods: {
    onModalOpen() {
      this.modalOpen = true
    },

    onModalClose() {
      this.modalOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.c-emissions-kpi-overview {
  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .name {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .indicator {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .tab {
      width: 8px;
    }
  }

  & .bold {
    font-weight: 600;
  }

  & .gray {
    color: #888;
  }
}
</style>
