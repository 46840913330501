<i18n>
{
  "de": {
    "yAxisTitle": {
      "ee": "Endenergieverbrauch in",
      "s12e": "Scope 1-2 Emissionen in"
    },
    "units": {
      "s12e": "t",
      "ee": "MWh"
    },
    "valueTypeUnit": {
      "ee": {
        "absolute": "MWh",
        "specific": "kWh/m²"
      },
      "s12e": {
        "absolute": "t CO₂e",
        "specific": "kg CO₂e/m²"
      }
    }
  }
}
</i18n>

<template>
  <BarChart :chart-data="processedChartData" :options="options" exportable class="c-meters-kpi-chart" />
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    kpiType: {
      type: String,
      required: true,
    },
    valuesType: {
      type: String, // 'absolute' or 'specific'
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator(this.kpiType)
    },

    yAxisTitle() {
      return `${this.$t(`yAxisTitle.${this.kpiType}`)} ${this.$t(`valueTypeUnit.${this.kpiType}.${this.valuesType}`)}`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    bigUnit() {
      if (this.sustainabilityIndicator === undefined) {
        return this.kpiType
      }
      if (this.sustainabilityIndicator === null) {
        return this.$t(`units.${this.kpiType}`)
      }
      return this.sustainabilityIndicator.getBigTargetUnit()
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            barPercentage: 0.6,
            grid: {
              drawOnChartArea: false,
            },
          },
          yAxis: {
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.yAxisTitle,
            },
          },
        },
        interaction: {
          mode: 'x',
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, {
                  precision: 1,
                  title: this.chartData.years[items[0].dataIndex],
                  unit: this.bigUnit,
                }),
              label: (item) =>
                this.getTooltipLabelWithPercentage(item, { unit: this.bigUnit, getTotalFn: this.getTotal }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    processedChartData() {
      const yearsCount = this.chartData?.years?.length || 0

      let datasets = []
      const meterTypes = ['END_ENERGY_HEATER', 'ELECTRICITY_TENANTS', 'ELECTRICITY_GENERAL', 'ELECTRICITY_PV']
      meterTypes.forEach((meterType) => {
        if (
          Object.prototype.hasOwnProperty.call(this.chartData.timeseries, meterType) &&
          this.chartData.timeseries[meterType].value.reduce((sum, v) => sum + v, 0) > 0
        ) {
          datasets.push({
            meterType: meterType,
            label:
              meterType === 'END_ENERGY_HEATER'
                ? this.getLabelForEndEnergyHeater(meterType)
                : this.$t(`_meterTypes.${meterType}`),
            backgroundColor: colorPalettes.meterTypeColors[meterType],
            data: this.chartData.timeseries[meterType].value.map((v, i) =>
              this.valuesType === 'absolute' ? v / 1000 : v / this.building.kpi.energy_area
            ),
            stack: 'measured',
            barThickness: 13,
          })
        }
      })

      return {
        labels:
          yearsCount < 10
            ? _.concat(
                this.chartData.years,
                _.map(_.range(11 - yearsCount), (i) => _.parseInt(_.last(this.chartData.years)) + i + 1)
              )
            : this.chartData.years,
        datasets: datasets,
      }
    },
  },

  methods: {
    getTotal(yearIdx) {
      return this.processedChartData.datasets
        .filter((d) => d.stack === 'measured')
        .reduce((pv, cv) => pv + Math.max(0, cv.data[yearIdx] ? cv.data[yearIdx] : 0), 0)
    },

    getLabelForEndEnergyHeater(meterType) {
      const energyCarrierId = this.building.meters.find(
        (meter) => meter.meter_type === 'END_ENERGY_HEATER'
      )?.energy_carrier_id
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyCarrierId)
      let energyCarrierName = ''

      if (energyCarrier) {
        if (energyCarrier.default) {
          energyCarrierName = this.$t(`_energyCarriers.${energyCarrier.name}`)
        } else {
          energyCarrierName = energyCarrier.name
        }
        return `${this.$t(`_meterTypes.${meterType}`)}: ${energyCarrierName}`
      } else {
        return `${this.$t(`_meterTypes.${meterType}`)}: ${this.$t('_energyCarriers.UNKNOWN')}`
      }
    },
  },
}
</script>
