<i18n>
{
  "de": {
    "noMeters": "Keine Datenpunkte",
    "meterTypeTitle": "Typ",
    "startMonthTitle": "Stichmonat",
    "months": {
      "1": "Januar",
      "2": "Februar",
      "3": "März",
      "4": "April",
      "5": "Mai",
      "6": "Juni",
      "7": "Juli",
      "8": "August",
      "9": "September",
      "10": "Oktober",
      "11": "November",
      "12": "Dezember"
    },
    "dataPointsTitle": "Datenpunkte",
    "addMeterButton": "Datenpunkt hinzufügen",
    "confirmDeleteMeter": "Wollen Sie diesen Datenpunkt und alle erfasste Werte wirklich löschen?",
    "valuesTitle": "Erfasste Jahre",
    "noValues": "Keine Werte",
    "total": "Total",
    "amountNormalizedTitle": "Mittelwert Endenergie",
    "normalizationTitle": "Witterungsbereinigung",
    "normalizationSource": "Methode: HGT20/12 (HEV Schweiz / MeteoSchweiz)"
  }
}
</i18n>

<template>
  <div class="c-meters-section-table">
    <div class="header-actions">
      <h3>{{ $t('dataPointsTitle') }}</h3>
      <Button
        v-if="getBuildingPermission('EDIT_METERS')"
        icon="plus"
        :text="$t('addMeterButton')"
        @click="onEditMeterModalOpen(null)"
      />
    </div>

    <NoDataMessage v-if="!Boolean(building.meters.length)" :message="$t('noMeters')" />

    <DetailList v-else has-header :has-footer="building.meters.length > 0" class="meters-list">
      <!-- Header -->
      <template #header>
        <HeaderEntry :title="$t('meterTypeTitle')" />
        <HeaderEntry :title="$t('startMonthTitle')" />
        <HeaderEntry :title="$t('valuesTitle')" />
        <HeaderEntry
          :title="$t('amountNormalizedTitle')"
          :infobox-title="$t('normalizationTitle')"
          :infobox-text="$t('normalizationSource')"
        >
          <template #unit>
            <div class="end-energy-units">
              <span class="absolute-unit">MWh</span>
              <span class="specific-unit">kWh/m²</span>
            </div>
          </template>
        </HeaderEntry>
        <div />
      </template>

      <!-- Meters -->
      <li v-for="meter in meters" :key="meter.id">
        <!-- Meter type -->
        <div v-if="meter.meter_type === 'END_ENERGY_HEATER'">
          <span class="bold">{{ $t(`_meterTypes.${meter.meter_type}`) }}:&nbsp;</span>
          <span>{{ energyCarrierIdentifier(meter.energy_carrier_id) }}</span>
        </div>
        <div v-else class="bold">
          {{ meter.meter_type ? $t(`_meterTypes.${meter.meter_type}`) : meter.meter_type }}
        </div>

        <!-- Meter starting month -->
        <div>{{ $t(`months.${meter.start_month}`) }}</div>

        <!-- Number of values / average -->
        <div v-if="meter.values.length">
          {{ meter.values.length }}
        </div>
        <div v-else>{{ $t('noValues') }}</div>

        <!-- End energy -->
        <div v-if="meter.values.length" class="end-energy-values">
          <span class="absolute-value">{{
            formatNumberWithThreshold(averageAmountNormalized(meter) / 1000, 2, 1, 1)
          }}</span>
          <span class="specific-value">{{
            formatNumberWithThreshold(specificAverageAmountNormalized(meter) / 1000, 2, 1, 1)
          }}</span>
        </div>
        <div v-else class="end-energy-values">
          <span class="absolute-value">-</span>
          <span class="specific-value">-</span>
        </div>

        <!-- Actions -->
        <ListButtonWrapper>
          <ListEditButton :viewIcon="!getBuildingPermission('EDIT_METERS')" @click="onEditMeterModalOpen(meter)" />
          <ListDeleteButton v-if="getBuildingPermission('EDIT_METERS')" @click="onDeleteMeter(meter)" />
        </ListButtonWrapper>
      </li>

      <template #footer>
        <span class="bold">{{ $t('total') }}</span>
        <div></div>
        <div></div>
        <div v-if="sumAmountNormalized > 0" class="end-energy-values">
          <span class="absolute-value">{{ formatNumberWithThreshold(sumAmountNormalized / 1000, 2, 1, 1) }}</span>
          <span class="specific-value">{{
            formatNumberWithThreshold(sumSpecificAmountNormalized / 1000, 2, 1, 1)
          }}</span>
        </div>
        <div v-else class="end-energy-values">
          <span class="absolute-value">-</span>
          <span class="specific-value">-</span>
        </div>
        <div></div>
      </template>
    </DetailList>

    <ListActionFeedback :message="error" @close="error = null" />

    <EditMeterModal
      v-if="editMeterModalOpen"
      :portfolio="portfolio"
      :building="building"
      :meter-id="editMeterId"
      @add="onAddMeter"
      @close="onEditMeterModalClose"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListEditButton from '@/components/shared/lists/ListEditButton.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ListActionFeedback from '@/components/shared/lists/ListActionFeedback.vue'
import NoDataMessage from '@/components/shared/lists/NoDataMessage.vue'
import EditMeterModal from '@/components/building/meters/EditMeterModal.vue'
import Button from '@/components/cui/inputs/Button.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  components: {
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton,
    DetailList,
    ListActionFeedback,
    NoDataMessage,
    EditMeterModal,
    Button,
    HeaderEntry,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      metersChartModalOpen: false,
      editMeterId: null,
      editMeterModalOpen: false,
      error: null,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    // Sort by meter type, then identifier
    meters() {
      return this.building.meters.slice().sort((a, b) => {
        return a.meter_type.localeCompare(b.meter_type)
      })
    },

    sumAmountNormalized() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.averageAmountNormalized(cv), 0)
      return sum
    },

    sumSpecificAmountNormalized() {
      const sum = this.building.meters.reduce((pv, cv) => pv + this.specificAverageAmountNormalized(cv), 0)
      return sum
    },

    climateStationName() {
      return this.building?.location_info?.data?.climate_station?.name || '-'
    },

    climateStationAltitude() {
      return this.building?.location_info?.data?.climate_station?.altitude
        ? `${this.building.location_info.data.climate_station.altitude} m`
        : '-'
    },

    climateStationNormTemp() {
      return this.building?.location_info?.data?.climate_station?.norm_temperature
        ? `${this.building.location_info.data.climate_station.norm_temperature} °C`
        : '-'
    },
  },

  methods: {
    ...mapActions({
      deleteMeter: 'building/deleteMeter',
    }),

    energyCarrierIdentifier(energyCarrierId) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyCarrierId)
      if (energyCarrier) {
        if (energyCarrier.default) {
          return this.$t(`_energyCarriers.${energyCarrier.name}`)
        } else {
          return energyCarrier.name
        }
      } else {
        return this.$t('_energyCarriers.UNKNOWN')
      }
    },

    averageAmountNormalized(meter) {
      if (meter.values.length === 0) {
        return null
      }
      const sum = meter.values.reduce((pv, cv) => pv + cv.amount_normalized, 0)
      return sum / meter.values.length
    },

    specificAverageAmountNormalized(meter) {
      const averageAmount = this.averageAmountNormalized(meter)
      if (averageAmount === null) return null

      const energyArea = this.building.kpi?.energy_area || 1
      return averageAmount / energyArea
    },

    onMetersChartModalOpen() {
      this.metersChartModalOpen = true
    },

    onMetersChartModalClose() {
      this.metersChartModalOpen = false
    },

    onEditMeterModalOpen(meter) {
      this.error = null
      if (meter) {
        this.editMeterId = meter.id
      } else {
        this.editMeterId = null
      }
      this.editMeterModalOpen = true
    },

    onEditMeterModalClose() {
      this.editMeterModalOpen = false
    },

    // Update currently edited meter id
    onAddMeter(result) {
      this.editMeterId = result?.id || null
    },

    onDeleteMeter(meter) {
      this.error = null
      setTimeout(async () => {
        if (confirm(this.$t('confirmDeleteMeter'))) {
          try {
            await this.deleteMeter({ id: meter.id, building_id: meter.building_id })
          } catch (error) {
            this.error = error
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-section-table {
  overflow: hidden;

  & .end-energy-units {
    display: flex;
    font-weight: normal;
    justify-content: flex-start;
    width: 100%;

    & .absolute-unit {
      width: 80px;
    }

    & .specific-unit {
      color: #919191;
    }
  }

  & .end-energy-values {
    display: flex;

    & .absolute-value {
      width: 80px;
    }

    & .specific-value {
      color: #919191;
    }
  }

  & .align-right {
    flex: 1 40px;
    text-align: right;
    justify-content: flex-end;
  }

  & .bold {
    font-weight: 600;
  }
}
</style>

<style lang="scss">
.c-meters-section-table {
  .header-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s);
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;

    & h3 {
      margin: 0;
    }
  }

  & .meters-list {
    & ul {
      border: none;

      & li {
        display: grid;
        grid-template-columns: 2fr 0.75fr 0.75fr 1fr 0.5fr;
      }

      & li.header {
        border-radius: 0;
      }

      & li:last-child {
        border-radius: 0;
      }
    }
  }
}
</style>
