<i18n>
{
  "de": {
    "detailsButtonTooltip": "Auswirkungen der Massnahme anzeigen",
    "measureTitle": {
      "SCENARIO": "Szenario-Massnahme",
      "SCENARIO_EDITED": "Geplante Massnahme",
      "PLANNED": "Geplante Massnahme",
      "COMPLETED": "Umgesetzte Massnahme"
    },
    "userDefined": "Benutzerdefiniert",
    "scenarioDefined": "CO₂mpass-generiert",
    "allScenarios": "Alle Szenarien",
    "currentScenario": "Aktuelles Szenario",
    "componentTitle": "Komponente",
    "investmentCostTitle": "Investitionskosten",
    "s12eTitle": "Scope 1-2",
    "reductionCostsTitle": "Vermeidungskosten",
    "measures": {
      "envelope": "Gebäudehülle",
      "heating": "Heizung",
      "electricity": "Stromkonfiguration",
      "pv": "Photovoltaik",
      "zones": "Flächen & Nutzungen"
    },
    "renovations": {
      "walls": "Sanierung Fassade",
      "roof": "Sanierung Dach",
      "basement": "Sanierung Kellerdecke",
      "windows": "Fensterersatz"
    },
    "reduction": "Reduktion",
    "energyGridRenovation": "Änderungen Netzanschluss",
    "heatingReplacement": "Heizungsersatz",
    "pvInstallation": "Photovoltaikanlage",
    "changeZones": "Änderung von Nutzung und Flächen",
    "noSectionsWarning": "Keine passende Fläche für PV-Anlage gefunden.",
    "total": "Gesamt",
    "reductionAmount": "Reduktion: {amount} kg CO₂e",
    "increaseAmount": "Zunahme: {amount} kg CO₂e",
    "noChange": "Keine Änderung",
    "measureYearIsInThePast": "Diese Massnahme sollte entweder umgesetzt, angepasst oder gelöscht werden. Sie wird nicht in die Berechnung der KPIs berücksichtigt.",
    "s12eInfobox": "Scope 1-2 Emissionen",
    "s12eInfoboxText": "Direkte Emissionen in t CO₂e nach der Massnahme. Die Reduktion (oder Zunahme) ist die Differenz zwischen den Scope 1-2 Emissionen vor und nach der Massnahme."
  }
}
</i18n>

<template>
  <div v-if="isMeasureDataComplete" class="c-building-measure-state">
    <TimelineElement :type="measure.phase" :is-last-measure="isLastMeasure" />
    <div class="measure-state-wrapper">
      <Card collapsible>
        <CardHeader slot="header">
          <template #prefix>
            <DataWarning v-if="measureYearIsInThePast">{{ $t('measureYearIsInThePast') }}</DataWarning>
            <div>{{ measure.year }}</div>
          </template>
          <template #title>
            <div>{{ $t(`measureTitle.${measure.phase}`) }}</div>
            <div v-if="measure.phase === 'SCENARIO_EDITED'" class="pill">
              <span>{{ $t('currentScenario') }}</span>
            </div>
            <div v-if="measure.phase === 'PLANNED'" class="pill">
              <span>{{ $t('allScenarios') }}</span>
            </div>
            <div v-if="measure.phase === 'SCENARIO_EDITED' || measure.phase === 'PLANNED'" class="pill">
              <span>{{ $t('userDefined') }}</span>
            </div>
            <div v-if="measure.phase === 'SCENARIO'" class="pill">
              <span>{{ $t('scenarioDefined') }}</span>
            </div>
            <ListButtonWrapper>
              <ListCompleteButton
                v-if="allowComplete"
                :tooltip="$t('completeMeasureTooltip')"
                @click="onCompleteMeasure"
              />
            </ListButtonWrapper>
          </template>

          <template #action>
            <ListButtonWrapper>
              <ListShowOrEditButton v-if="showEdit" :allow-edit="allowEdit" @click="openEditModal" />
              <ListDeleteButton v-if="allowDelete" @click="onDeleteMeasure" />
              <ListDetailsButton :tooltip="$t('detailsButtonTooltip')" @click="openViewModal" />
            </ListButtonWrapper>
          </template>
        </CardHeader>

        <!-- Measure Details -->
        <CardContent slot="content">
          <div class="content-wrapper">
            <DetailList has-header class="measure-details">
              <!-- Header -->
              <template #header>
                <div>{{ $t('componentTitle') }}</div>
                <HeaderEntry
                  :title="$t('s12eTitle')"
                  :infobox-title="$t('s12eInfobox')"
                  :infobox-text="$t('s12eInfoboxText')"
                  :unit="`t CO₂e (${$t('reduction')})`"
                  align-right
                />
                <HeaderEntry :title="$t('investmentCostTitle')" unit="CHF" align-right />
                <HeaderEntry :title="$t('reductionCostsTitle')" unit="CHF/kg CO₂e" align-right />
              </template>

              <!-- Measure Actions -->

              <!-- Total -->
              <li>
                <div>
                  <strong>{{ $t('total') }}</strong>
                </div>
                <div class="align-right">
                  <strong>{{ formatNumberWithThreshold(measure.kpi.s12e / 1000, 2, 1, 1) }}</strong>
                  <span class="reduction-percentage">
                    <VPopover placement="bottom" trigger="hover">
                      <span v-if="measure.kpi.s12e === lastKpi.s12e">(-)</span>
                      <span v-else-if="measure.kpi.s12e > lastKpi.s12e"
                        >(↑ {{ formatNumber(((measure.kpi.s12e - lastKpi.s12e) / lastKpi.s12e) * 100, 1) }}%)</span
                      >
                      <span v-else
                        >(↓ {{ formatNumber(((measure.kpi.s12e - lastKpi.s12e) / lastKpi.s12e) * 100 * -1, 1) }}%)</span
                      >

                      <template slot="popover">
                        <main v-if="measure.kpi.s12e < lastKpi.s12e">
                          {{
                            $t('reductionAmount', {
                              amount: formatNumber(Math.abs(measure.kpi.s12e - lastKpi.s12e), 1),
                            })
                          }}
                        </main>
                        <main v-else-if="measure.kpi.s12e === lastKpi.s12e">
                          {{ $t('noChange') }}
                        </main>
                        <main v-else>
                          {{
                            $t('increaseAmount', { amount: formatNumber(Math.abs(lastKpi.s12e - measure.kpi.s12e), 1) })
                          }}
                        </main>
                      </template>
                    </VPopover>
                  </span>
                </div>
                <div class="align-right">
                  <strong>{{ formatNumber(measure.total_costs, -3) }}</strong>
                </div>
                <div class="align-right">
                  <strong v-if="measure.kpi.s12e < lastKpi.s12e">
                    {{ formatNumberWithThreshold(measure.total_costs / (lastKpi.s12e - measure.kpi.s12e), 0, 10, -1) }}
                  </strong>
                  <strong v-else>-</strong>
                </div>
              </li>

              <!-- Zones -->
              <li v-if="measure.actions.zones !== null">
                <div>
                  <span :style="{ color: colorPalettes.measureColors.ZONES }">■</span>
                  <span>{{ $t('changeZones') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">-</div>
                <div class="align-right"></div>
              </li>

              <!-- PV -->
              <li v-if="measure.actions.pv !== null && measure.next_state.pv.sections.length > 0">
                <div>
                  <span :style="{ color: colorPalettes.measureColors.PV }">■</span>
                  <span>{{ $t('pvInstallation') }} {{ formatNumber(pvCapacity, 0) }}&nbsp;kW</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.pv.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <li v-if="measure.actions.pv !== null && measure.next_state.pv.sections.length === 0">
                <div class="partial-measure__warning">
                  <span :style="{ color: colorPalettes.measureColors.PV }">■</span>
                  <span> {{ $t('pvInstallation') }} {{ formatNumber(0, 0) }}&nbsp;kW </span>
                  <DataWarning>{{ $t('noSectionsWarning') }}</DataWarning>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(0, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Heating -->
              <li v-if="measure.actions.heating !== null">
                <div>
                  <span :style="{ color: colorPalettes.measureColors.HEATING }">■</span>
                  <span>
                    <span>{{ $t('heatingReplacement') }}</span>
                    <br />
                    <span>{{ getHeatingTypeName(measure.actions.heating.heaters[0].heating_type_id) }}</span>
                  </span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.heating.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Envelope -->
              <!-- Windows -->
              <li
                v-if="
                  measure.actions.envelope !== null &&
                  measure.actions.envelope.windows !== null &&
                  measure.actions.envelope.windows.standard !== 'NONE'
                "
              >
                <div>
                  <span :style="{ color: colorPalettes.measureColors.WINDOWS }">■</span>
                  <span>{{ $t('renovations.windows') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.windows.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Walls -->
              <li
                v-if="
                  measure.actions.envelope !== null &&
                  measure.actions.envelope.walls !== null &&
                  measure.actions.envelope.walls.standard !== 'NONE'
                "
              >
                <div>
                  <span :style="{ color: colorPalettes.measureColors.WALLS }">■</span>
                  <span>{{ $t('renovations.walls') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.walls.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Roof -->
              <li
                v-if="
                  measure.actions.envelope !== null &&
                  measure.actions.envelope.roof !== null &&
                  measure.actions.envelope.roof.standard !== 'NONE'
                "
              >
                <div>
                  <span :style="{ color: colorPalettes.measureColors.ROOF }">■</span>
                  <span>{{ $t('renovations.roof') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.roof.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Basement -->
              <li
                v-if="
                  measure.actions.envelope !== null &&
                  measure.actions.envelope.basement !== null &&
                  measure.actions.envelope.basement.standard !== 'NONE'
                "
              >
                <div>
                  <span :style="{ color: colorPalettes.measureColors.BASEMENT }">■</span>
                  <span>{{ $t('renovations.basement') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.basement.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>

              <!-- Electricity -->
              <li v-if="measure.actions.electricity !== null">
                <div>
                  <span :style="{ color: colorPalettes.measureColors.ELECTRICITY }">■</span>
                  <span>{{ $t('energyGridRenovation') }}</span>
                </div>
                <div class="align-right"></div>
                <div class="align-right">{{ formatNumber(measure.costs.electricity.costs, -3) }}</div>
                <div class="align-right"></div>
              </li>
            </DetailList>

            <!-- KPI Report card -->
            <KpiReportCard
              v-if="measure.kpi"
              :s12e-value="measure.kpi.s12e / measure.kpi.energy_area"
              :energy-efficiency-value="measure.kpi.pe / measure.kpi.energy_area"
              :heating-efficiency-value="measure.kpi.rh_demand / measure.kpi.energy_area"
              :energy-efficiency-ratio="peRatio"
              :heating-efficiency-ratio="rhRatio"
            />
          </div>
        </CardContent>
      </Card>
    </div>

    <!-- Modals -->
    <KpiChangeModal v-if="viewModalOpen" :measure="measure" :last-kpi="lastKpi" @close="closeViewModal" />
    <EditMeasureModal
      v-if="editModalOpen"
      :building="building"
      :portfolio="portfolio"
      :selected-scenario="selectedScenario"
      :measure="measure"
      @close="closeEditModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VPopover } from 'v-tooltip'
import colorPalettes from '@/services/color-palettes.js'

import KpiChangeModal from '@/components/building/measures/KpiChangeModal.vue'
import EditMeasureModal from '@/components/building/measures/edit/EditMeasureModal.vue'
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'
import TimelineElement from '@/components/building/measures/TimelineElement.vue'
import KpiReportCard from '@/components/building/measures/KpiReportCard.vue'
import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper.vue'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton.vue'
import ListDetailsButton from '@/components/shared/lists/ListDetailsButton.vue'
import ListCompleteButton from '@/components/shared/lists/ListCompleteButton.vue'
import ListShowOrEditButton from '@/components/shared/lists/ListShowOrEditButton.vue'
import DataWarning from '@/components/shared/DataWarning.vue'

export default {
  components: {
    VPopover,
    KpiChangeModal,
    EditMeasureModal,
    TimelineElement,
    Card,
    CardHeader,
    CardContent,
    DetailList,
    HeaderEntry,
    KpiReportCard,
    ListButtonWrapper,
    ListDeleteButton,
    ListDetailsButton,
    ListCompleteButton,
    ListShowOrEditButton,
    DataWarning,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    measure: {
      type: Object,
      required: true,
    },
    selectedScenario: {
      type: Object,
      required: true,
    },
    lastKpi: {
      type: Object,
      default: () => {},
    },
    isLastMeasure: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viewModalOpen: false,
      editModalOpen: false,
      colorPalettes,
    }
  },

  computed: {
    ...mapGetters({
      getBuildingPermission: 'permissions/getBuildingPermission',
    }),

    showEdit() {
      return this.getBuildingPermission('EDIT_MEASURES') || this.measure.phase !== 'SCENARIO'
    },

    allowEdit() {
      return this.getBuildingPermission('EDIT_MEASURES')
    },

    allowComplete() {
      return this.measure.phase !== 'COMPLETED' && !this.allowEdit && this.getBuildingPermission('COMPLETE_MEASURES')
    },

    allowDelete() {
      return this.measure.phase !== 'SCENARIO' && this.getBuildingPermission('DELETE_MEASURES')
    },

    isMeasureDataComplete() {
      return (
        this.measure &&
        this.measure.year &&
        this.measure.phase &&
        this.measure.total_costs !== undefined &&
        this.measure.kpi &&
        this.measure.kpi.s12e !== undefined &&
        this.measure.actions &&
        this.measure.costs &&
        this.lastKpi &&
        this.lastKpi.s12e !== undefined
      )
    },

    scenario() {
      return this.portfolio.scenarios.find((s) => s.id === this.measure.scenario_id)
    },

    rhRatio() {
      return this.measure.kpi && this.measure.kpi.rh_demand / this.measure.kpi.energy_area / this.measure.kpi.rh_limit
    },

    peRatio() {
      return this.measure.kpi && this.measure.kpi.pe / this.measure.kpi.energy_area / this.measure.kpi.pe_ref
    },

    pvCapacity() {
      return this.measure.pv_sections.reduce((pv, cv) => pv + cv.capacity, 0)
    },

    measureYearIsInThePast() {
      return (
        this.measure.year < new Date().getFullYear() &&
        ['PLANNED', 'SCENARIO', 'SCENARIO_EDITED'].includes(this.measure.phase)
      )
    },
  },

  methods: {
    ...mapActions({
      completeMeasure: 'building/completeMeasure',
      deleteMeasure: 'building/deleteMeasure',
    }),

    openEditModal() {
      this.editModalOpen = true
    },

    closeEditModal() {
      this.editModalOpen = false
    },

    openViewModal() {
      this.viewModalOpen = true
    },

    closeViewModal() {
      this.viewModalOpen = false
    },

    async onCompleteMeasure() {
      if (confirm(this.$t('confirmCompleteMeasure'))) {
        try {
          this.error = null
          await this.completeMeasure(this.measure)
          this.error = null
        } catch (error) {
          this.error = error
        }
      }
    },

    async onDeleteMeasure() {
      if (confirm(this.$t('confirmDeleteMeasure'))) {
        try {
          this.error = null
          await this.deleteMeasure(this.measure)
          this.error = null
        } catch (error) {
          this.error = error
        }
      }
    },

    getHeatingTypeName(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.name === heatingTypeId)
      if (heatingType) {
        if (heatingType.default) {
          return this.$t(`_heatingTypes.${heatingType.name}`)
        } else {
          return heatingType.name
        }
      } else {
        return this.$t('_heatingTypes.UNKNOWN')
      }
    },

    getEnergyGridName(energyGridId) {
      const energyCarrier = this.portfolio.energy_carriers.find((ec) => ec.id === energyGridId)
      if (energyCarrier && energyCarrier.name !== 'ELECTRICITY_GRID') {
        return energyCarrier.name
      } else {
        return this.$t('_energyCarriers.ELECTRICITY_GRID')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-building-measure-state {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;

  & .measure-state-wrapper {
    flex: 1;
    width: 100%;
    margin: 20px 20px 0 0;

    & .content-wrapper {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      width: 100%;

      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }

      @media screen and (max-width: 1280px) {
        flex-direction: row;
      }

      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      & .measure-details {
        flex: 1;
        border-right: var(--box-border);
        overflow: auto;
        width: 0;

        @media screen and (max-width: 1400px) {
          width: 100%;
          border-right: none;
          border-bottom: var(--box-border);
        }

        @media screen and (max-width: 1280px) {
          width: 0;
          border-right: var(--box-border);
          border-bottom: none;
        }

        @media screen and (max-width: 960px) {
          width: 100%;
          border-right: none;
          border-bottom: var(--box-border);
        }

        & .partial-measure__warning {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: var(--spacing-xs);
        }
      }

      & strong {
        font-weight: 600;
      }

      & .reduction-percentage {
        opacity: 0.5;
        margin-left: var(--spacing-xs);
      }
    }
  }
}
</style>

<style lang="scss">
.c-building-measure-state {
  & .pill {
    display: flex;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: 2px var(--spacing-s);
    border: var(--box-border);
    border-radius: 40px;
    font-size: 14px;

    & > span {
      font-weight: 400;
      display: inline-block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  & .detail-list.measure-details {
    & > ul {
      border: none;
      min-width: max-content;
      width: 100%;

      & > li.header {
        font-size: 13px;

        & > * > * {
          width: 100% !important;
        }
      }

      & > li {
        font-size: 14px;

        & > * {
          flex: 1;
          min-width: 120px;
          width: 100% !important;

          &:first-child {
            display: flex;
            flex-direction: row;

            & > span {
              display: inline;

              &:first-child {
                margin-right: 6px;
                pointer-events: none;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
              }
            }
          }
        }

        & > .align-right {
          text-align: right;
        }

        &:last-child {
          border-radius: 0;
        }
      }
    }
  }

  & .modal-wrapper {
    & ul {
      border: var(--box-border);
    }
  }
}
</style>
