<i18n>
{
  "de": {
    "autoEnabled": "Automatisch",
    "autoDisabled": "(Manuell)",
    "autoEnabledQ": "Automatisch",
    "yearHeating": "Baujahr",
    "replacementYearHeating": "Jahr Ersatzplanung",
    "nextReplacementInfoBoxTitle": "Jahr nächster Heizungsersatz aus Instandsetzungsplanung",
    "nextReplacementInfoBox": "Wenn dieses Feld leer gelassen wird, wird eine Standardlebensdauer angenommen.",
    "distributionReplacementYearHeating": "Ersatzplanung",
    "distributionNextReplacementInfoBoxTitle": "Jahr nächster Eingriff aus Instandsetzungsplanung",
    "distributionNextReplacementInfoBox": "Wenn dieses Feld leer gelassen wird, wird eine Standardlebensdauer angenommen.",
    "remarksTitle": "Bemerkungen",
    "heatersTitle": "Wärmeerzeugung",
    "yearEstimatedTooltip": "Das Alter der Wärmeerzeugung ist unsicher",
    "distributionTitle": "Wärmeverteilung",
    "distributionYearTitle": "Baujahr",
    "distributionRemarksTitle": "Bemerkungen",
    "distributionTypeTitle": "Typ",
    "distributionOptions": {
      "UNKNOWN": "Unbekannt",
      "FLOOR_HEATING": "Bodenheizung",
      "CEILING_HEATING": "Deckenheizung",
      "RADIATORS": "Radiatoren",
      "AIR": "Warmluft"
    }
  }
}
</i18n>

<template>
  <Box class="c-view-heating">
    <h3>{{ $t('heatersTitle') }}</h3>
    <FormRow :label="$t('yearHeating')">
      <NumericInput
        v-model="model.year"
        noFormatting
        int
        optional
        :min="1400"
        :max="2100"
        :edit="false"
        class="limit-width"
        @validation="onValidation($event, 'year')"
      />
      <EstimateInput v-model="model.year_estimated" :edit="false" :tooltip="$t('yearEstimatedTooltip')" />
    </FormRow>
    <!-- <FormRow
      :label="$t('replacementYearHeating')"
      :info-title="$t('nextReplacementInfoBoxTitle')"
      :info-text="$t('nextReplacementInfoBox')"
    >
      <NumericInput
        v-model="model.replacement_year"
        class="limit-width"
        noFormatting
        optional
        int
        :min="1400"
        :max="2100"
        :edit="false"
        @validation="onValidation($event, 'replacement_year')"
      />
    </FormRow>
    <FormRow v-if="model.remarks" :label="$t('remarksTitle')">
      <TextInput
        v-model="model.remarks"
        :edit="false"
        :allow-empty="true"
        @validation="onValidation($event, 'remarks')"
      />
    </FormRow> -->

    <!-- Heaters -->
    <EditHeatersTable v-model="model.heaters" :heaters-results="heatersResults" :portfolio="portfolio" :edit="false" />

    <!-- Heating Distribution -->
    <!-- <h3>{{ $t('distributionTitle') }}</h3>
    <FormRow :label="$t('distributionTypeTitle')">
      <div v-if="false" style="max-width: 300px; display: inline-block">
        <v-select
          id="distribution-options"
          v-model="model.distribution"
          :options="distributionOptions"
          :clearable="false"
          :reduce="(t) => t.id"
          class="select-width"
        />
      </div>
      <div v-else>
        {{ $t(`distributionOptions.${model.distribution}`) }}
      </div>
    </FormRow>
    <FormRow v-if="model.distribution_year" :label="$t('distributionYearTitle')">
      <NumericInput
        v-model="model.distribution_year"
        class="limit-width"
        noFormatting
        int
        optional
        :min="1400"
        :max="2100"
        :edit="false"
        @validation="onValidation($event, 'distributionYear')"
      />
    </FormRow>
    <FormRow
      v-if="model.distribution_replacement_year"
      :label="$t('distributionReplacementYearHeating')"
      :info-title="$t('distributionNextReplacementInfoBoxTitle')"
      :info-text="$t('distributionNextReplacementInfoBox')"
    >
      <NumericInput
        v-model="model.distribution_replacement_year"
        class="limit-width"
        noFormatting
        optional
        int
        :min="1400"
        :max="2100"
        :edit="false"
        @validation="onValidation($event, 'distributionReplacementYear')"
      />
    </FormRow>
    <FormRow v-if="model.distribution_remarks" :label="$t('distributionRemarksTitle')">
      <TextInput v-model="model.distribution_remarks" :edit="false" :allow-empty="true" />
    </FormRow> -->
  </Box>
</template>

<script>
import Box from '@/components/cui/layout/Box.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import EditHeatersTable from '@/components/building/edit/EditHeatersTable.vue'
// import TextInput from '@/components/shared/forms/TextInput.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'

export default {
  components: {
    Box,
    NumericInput,
    EstimateInput,
    EditHeatersTable,
    FormRow,
    // TextInput,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      distributionOptions: [
        { id: 'UNKNOWN', label: this.$t('distributionOptions.UNKNOWN') },
        { id: 'FLOOR_HEATING', label: this.$t('distributionOptions.FLOOR_HEATING') },
        { id: 'CEILING_HEATING', label: this.$t('distributionOptions.CEILING_HEATING') },
        { id: 'RADIATORS', label: this.$t('distributionOptions.RADIATORS') },
        { id: 'AIR', label: this.$t('distributionOptions.AIR') },
      ],
    }
  },

  computed: {
    allowEdit() {
      return this.getBuildingPermission('EDIT_HEATING') && this.falseAllowed
    },

    heatersResults() {
      return this.building.current_state.result && this.building.current_state.result.heaters
    },
  },

  watch: {
    building() {
      this.resetModel()
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    resetModel() {
      this.model = {
        year: this.building.current_state.heating.year,
        year_estimated: this.building.current_state.heating.year_estimated,
        replacement_year: this.building.current_state.heating.replacement_year,
        remarks: this.building.current_state.heating.remarks,
        heaters: [],
        distribution: this.building.current_state.heating.distribution,
        distribution_year: this.building.current_state.heating.distribution_year,
        distribution_remarks: this.building.current_state.heating.distribution_remarks,
        distribution_replacement_year: this.building.current_state.heating.distribution_replacement_year,
      }
      for (let i = 0; i < this.building.current_state.heating.heaters.length; i++) {
        const heater = this.building.current_state.heating.heaters[i]
        this.model.heaters.push({
          heating_type_id: this.getHeatingTypeRawName(heater.heating_type_id),
          heat_grid_identifier: heater.heat_grid_identifier,
          rh_share: heater.rh_share,
          dhw_share: heater.dhw_share,
          energy_calibration: heater.energy_calibration !== null ? heater.energy_calibration : null,
          remarks: heater.remarks,
        })
      }
    },

    getHeatingTypeRawName(heatingTypeId) {
      const heatingType = this.portfolio.heating_types.find((ht) => ht.id === heatingTypeId)
      if (heatingType) {
        return heatingType.name
      } else {
        return 'UNKNOWN'
      }
    },

    onHeatersChange(heaters) {
      this.model.heaters = heaters
    },
  },
}
</script>

<style lang="scss">
.c-edit-heating {
  & .limit-width {
    max-width: 150px;
    display: inline-block;
  }

  & .select-width {
    min-width: 150px;
  }
}
</style>
