<i18n>
{
  "de": {
    "title": "Kategorien",
    "noCategories": "Keine Kategorien",
    "addCategory": "Kategorie hinzufügen",
    "editCategories": "Kategorien bearbeiten"
  }
}
</i18n>

<template>
  <Card collapsible class="c-tag-group-overview">
    <CardHeader slot="header" :title="$t('title')" icon="cards/categories">
      <template #action>
        <Button
          v-if="manualTags?.length"
          className="settings-tag-groups-button"
          :href="`/portfolios/${portfolio.id}/settings/tag-groups`"
          :text="$t('editCategories')"
        />
      </template>
    </CardHeader>

    <CardContent slot="content">
      <!-- List of manual tags -->
      <List v-if="manualTags?.length" divider>
        <TagGroupSummaryRow
          v-for="(tagGroup, tagIdx) in manualTags"
          :key="tagIdx"
          :portfolio="portfolio"
          :building="building"
          :tag-group="tagGroup"
        />
      </List>

      <!-- Empty state -->
      <div v-else class="no-categories-container">
        <span>{{ $t('noCategories') }}</span>
        <Button
          className="settings-tag-groups-button"
          icon="plus"
          :href="`/portfolios/${portfolio.id}/settings/tag-groups`"
          :text="$t('addCategory')"
        />
      </div>
    </CardContent>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import Button from '@/components/cui/inputs/Button.vue'
import TagGroupSummaryRow from '@/components/building/overview/TagGroupSummaryRow.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    TagGroupSummaryRow,
    Button,
  },

  props: {
    portfolio: {
      type: Object,
    },
    building: {
      type: Object,
      required: true,
    },
    manualTags: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.c-tag-group-overview {
  & .settings-tag-groups-button {
    padding: 8px 12px;
    background-color: transparent;

    & img {
      width: 16px;
      margin-right: 4px;
    }
  }

  & .no-categories-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-s);
  }
}
</style>
