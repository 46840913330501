<i18n>
{
  "de": {
    "title": "Liegenschaft",
    "scrollToMeasures": "Zum Lebenszyklus & Massnahmen"
  }
}
</i18n>

<template>
  <Card collapsible class="c-status-and-location-overview">
    <CardHeader slot="header" :title="$t('title')" icon="cards/location" />
    <CardContent slot="content">
      <List divider>
        <DescriptionAndStatusSummaryRow :building="building" />
        <LocationSummaryRow :building="building" />
        <BuildingSummaryRows :building="building" :portfolio="portfolio" />
      </List>

      <!-- Link to measures -->
      <div class="link-to-measures-container">
        <div>
          <Button
            className="settings-tag-groups-button"
            :text="$t('scrollToMeasures')"
            @click="onScrollToMeasuresSection"
          />
        </div>
      </div>
    </CardContent>
  </Card>
</template>

<script>
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import List from '@/components/cui/data-display/List.vue'
import Button from '@/components/cui/inputs/Button.vue'
import DescriptionAndStatusSummaryRow from '@/components/building/overview/DescriptionAndStatusSummaryRow.vue'
import LocationSummaryRow from '@/components/building/overview/LocationSummaryRow.vue'
import BuildingSummaryRows from '@/components/building/overview/BuildingSummaryRows.vue'

export default {
  components: {
    Card,
    CardHeader,
    CardContent,
    List,
    Button,
    DescriptionAndStatusSummaryRow,
    LocationSummaryRow,
    BuildingSummaryRows,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  methods: {
    //
    onScrollToMeasuresSection() {
      this.$nextTick(() => {
        const measuresSection = document.getElementById('measures')
        if (measuresSection) {
          measuresSection.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.c-status-and-location-overview {
  .link-to-measures-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 64px;
  }
}
</style>
