<i18n>
{
  "de": {
    "gisError": "GIS-Abfragefehler",
    "properties": "Liegenschaften",
    "deleteBuilding": "Liegenschaft löschen",
    "deleteConfirmation": "Wollen Sie diese Liegenschaft wirklich löschen?",
    "exportBuilding": "JSON-Export",
    "pvProfitabilityTitle": "Variantenvergleich Photovoltaik",
    "measuresTitle": "Massnahmen",
    "isBeingCalculated": "wird berechnet ...",
    "map": "Karte",
    "bubble": "Streudiagramm",
    "allowEdit": "Admin: Erweitertes Bearbeiten",
    "disallowEdit": "Admin: Zurück zum eingeschränkten Bearbeiten"
  }
}
</i18n>

<template>
  <SplitLayout :portfolio="portfolio" :ready="portfolioReady && buildingReady" class="p-building-page">
    <template #header>
      <!-- Header -->
      <PageTitle v-if="building" :title="building.description" :portfolio="portfolio">
        <template #default>
          <div>/</div>
          <router-link :to="`/portfolios/${portfolio.id}/buildings`">{{ $t('properties') }}</router-link>
        </template>

        <template #details>
          <div class="building-page-queue">
            <BuildingQueuedIndicator
              v-if="buildingSimulationQueue(portfolio.id).includes(building.id)"
              :text="$tc('isBeingCalculated')"
            />
            <!-- Location -->
            <span v-if="building.zip_code">{{ building.zip_code }}</span>
            <span v-if="building.municipality">{{ building.municipality }}</span>
            <span v-if="!building.municipality && building.location_info.state === 'OK'">
              {{ building.location_info.data.municipality }}
            </span>
            <!-- Identifier -->
            <span class="identifier">
              {{ building.identifier }}
            </span>
            <!-- Active status -->
            <ActiveStatus :building="building" class="active-status" />
            <!--  -->
            <StatusPill :building="building" class="status-pill" />
          </div>
        </template>

        <template #actions>
          <!-- Admin edit override -->
          <button
            v-if="canSwitchPermissions"
            type="button"
            class="button"
            :class="{ danger: isAdminEditOverrideActive(building.id) }"
            @click="onSwitchPermissions"
          >
            {{ isAdminEditOverrideActive(building.id) ? $t('disallowEdit') : $t('allowEdit') }}
          </button>
        </template>
      </PageTitle>
    </template>

    <!-- Main Panel: Buiilding Details -->
    <template #default>
      <!-- Map and KPIs on smaller screens -->
      <BuildingMapAndKpis :building="building" :chart-data="chartData" class="side-tray" layout="horizontal" />

      <!-- Overview Section -->
      <OverviewSection
        v-if="building"
        class="building-page-section"
        :building="building"
        :portfolio="portfolio"
        :chart-data="chartData"
      />

      <!-- Meters Section -->
      <MetersSection v-if="building" :building="building" :portfolio="portfolio" />

      <!-- Measures Section-->
      <MeasuresSection v-if="building" :building="building" :portfolio="portfolio" />

      <!-- PV Comparison Section -->
      <template v-if="portfolio.enabled_features.PV_PROFITABILITY">
        <PvComparisonSection v-if="building" :building="building" :portfolio="portfolio" />
      </template>

      <!-- Buttons: Delete / Export -->
      <ButtonWrapper v-if="building">
        <Button
          v-if="getBuildingPermission('DELETE')"
          :text="$t('deleteBuilding')"
          icon="trash"
          class="button--delete"
          @click="onDelete"
        />
        <Button v-if="getBuildingPermission('VIEW')" :text="$t('exportBuilding')" @click="onExport" />
      </ButtonWrapper>
    </template>

    <!-- Side Panel: Map and KPI Charts -->
    <template #side-panel>
      <BuildingMapAndKpis :building="building" :chart-data="chartData" class="side-tray" layout="vertical" />
    </template>
  </SplitLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SplitLayout from '@/pages/layouts/SplitLayout.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'
import BuildingMixin from '@/pages/vuex-mixins/BuildingMixin.vue'
import ChartDataMixin from '@/pages/vuex-mixins/ChartDataMixin.vue'

import PageTitle from '@/components/shared/PageTitle.vue'
import BuildingMapAndKpis from '@/components/building/BuildingMapAndKpis.vue'
import OverviewSection from '@/components/building/OverviewSection.vue'
import MetersSection from '@/components/building/meters/MetersSection.vue'
import MeasuresSection from '@/components/building/measures/MeasuresSection.vue'
import PvComparisonSection from '@/components/building/PvComparisonSection.vue'
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import Button from '@/components/cui/inputs/Button.vue'
import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'
import StatusPill from '@/components/building/overview/StatusPill.vue'
import ActiveStatus from '@/components/building/overview/ActiveStatus.vue'

import exportService from '@/services/export-service.js'

export default {
  name: 'buildingDetails',

  mixins: [
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    BuildingMixin, // Provides: building, buildingReady, buildingLoading
    ChartDataMixin, // Provides: chartData, chartDataLoading, chartDataLoaded
  ],

  components: {
    BuildingQueuedIndicator,
    ButtonWrapper,
    Button,
    SplitLayout,
    BuildingMapAndKpis,
    PvComparisonSection,
    OverviewSection,
    MetersSection,
    MeasuresSection,
    PageTitle,
    StatusPill,
    ActiveStatus,
  },

  data() {
    return {
      pageTitle: null,
      deletePending: false,
      deleteError: null,
      exportPending: false,
      exportError: null,
    }
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
      getBuildingPermission: 'permissions/getBuildingPermission',
      canSwitchPermissions: 'permissions/canSwitchPermissions',
      isAdminEditOverrideActive: 'permissions/isAdminEditOverrideActive',
    }),
  },

  watch: {
    building() {
      this.onBuildingUpdated()
    },
  },

  mounted() {
    if (this.building_id) {
      this.__loadBuildingDetailsById(this.building_id)
    }
  },

  methods: {
    ...mapActions({
      deleteBuilding: 'building/deleteBuilding',
      refreshBuildings: 'portfolioBuildingsForList/refresh',
      switchBuildingPermissions: 'permissions/switchBuildingPermissions',
      setBuildingMapAndKpisMode: 'ui/setBuildingMapAndKpisMode',
      __loadBuildingDetailsById: 'building/refreshDetailsById',
    }),

    //
    onExport() {
      exportService.exportBuildingJson(this.building)
    },

    //
    async onDelete() {
      const confirmationText = this.$t('deleteConfirmation')
      const portfolioId = this.building.portfolio_id
      if (confirm(confirmationText)) {
        try {
          this.deletePending = true
          this.deleteError = null
          await this.deleteBuilding({
            buildingId: this.building.id,
            portfolioId: this.portfolio.id,
          })
        } catch (error) {
          this.deleteError = error
        }
        this.deletePending = false
        await this.refreshBuildings()
        this.$router.push({ name: 'portfolioBuildings', params: { portfolio_id: portfolioId } })
      }
    },

    //
    onBuildingUpdated() {
      this.pageTitle = this.building?.description
      this.$emit('updateHead')
    },

    //
    onSwitchPermissions() {
      this.switchBuildingPermissions(this.building)
    },
  },

  head() {
    return {
      title: () => {
        if (this.pageTitle) {
          return { inner: `${this.pageTitle}` }
        }
        if (this.building) {
          return { inner: this.building.description }
        }
      },
    }
  },
}
</script>

<style lang="scss">
.p-building-page {
  & .building-page-section {
    margin-bottom: var(--spacing-l);
  }
}

.building-page-queue {
  display: flex;
  flex-direction: row;
  gap: 6px;
  line-height: 24px;

  & .identifier,
  & .active-status,
  & .status-pill {
    margin-left: 4px;
  }

  & .identifier,
  & .active-status {
    color: #888;
  }

  & .identifier {
    text-transform: uppercase;
  }
}
</style>

<style lang="scss" scoped>
.danger {
  background-color: var(--warning-color);
}
</style>
