<!--
Provides: chartData, chartDataLoading, chartDataReady
Requires: portfolio_id
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getChartData: 'chartData/getChartData',
      getChartDataLoading: 'chartData/getChartDataLoading',
      getChartDataLoaded: 'chartData/getChartDataLoaded',
    }),

    chartData() {
      return this.getChartData()
    },

    chartDataLoading() {
      return this.getChartDataLoading()
    },

    chartDataLoaded() {
      return this.getChartDataLoaded()
    },
  },

  watch: {
    portfolio_id() {
      this.loadChartData({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.loadChartData({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      loadChartData: 'chartData/load',
    }),
  },
}
</script>
