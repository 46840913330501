<template>
  <div :class="`c-cui-card ${className}`">
    <div @click="toggleCollapse">
      <slot name="header" />
    </div>
    <template v-if="!isCollapsed">
      <slot name="content" />
      <slot name="footer" />
    </template>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    /**
     * If true, the card can be collapsed by clicking on the header.
     * CardHeader, CardContent and CardFooter slot prop have to be set
     *   to render all necessary components in collapsible container:
     *   <CardHeader slot="header" />
     *   <CardContent slot="content" />
     *   <CardFooter slot="footer" />
     */
    collapsible: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isCollapsed: false, // Used also in CardHeader to update styles and arrow icon
    }
  },

  watch: {
    collapsed: {
      immediate: true,
      handler(newValue) {
        this.isCollapsed = newValue
      },
    },
  },

  methods: {
    toggleCollapse(event) {
      if (this.collapsible && !(event.target.tagName === 'BUTTON' || event.target.closest('button'))) {
        this.isCollapsed = !this.isCollapsed
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-cui-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: var(--box-border);
  border-radius: var(--box-radius);
  background-color: #fff;
  overflow: hidden;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #ddd;
  }
}
</style>
