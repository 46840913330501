<i18n>
{
  "de": {
    "kpiBeforeTitle": "Letzte Massnahme",
    "kpiAfterTitle": "Nach Massnahme",
    "kpiAreaLabel": "Energiebezugsfläche",
    "kpiRhLabel": "Nutzenergie Raumwärme",
    "kpiDhwDemandLabel": "Nutzenergie Warmwasser",
    "kpiHeatingPowerLabel": "Heizleistung",
    "kpiElDemandLabel": "Strombedarf",
    "kpiElProductionLabel": "Stromproduktion",
    "kpiElOwnUseLabel": "Stromeigenverbrauch"
  }
}
</i18n>
<template>
  <div class="kpi-grid">
    <div></div>
    <div class="kpi-grid-header">{{ $t('kpiBeforeTitle') }}</div>
    <div class="kpi-grid-header">{{ $t('kpiAfterTitle') }}</div>
    <div></div>

    <KpiChangeRow
      :label="$t('kpiAreaLabel')"
      :before="kpiBefore.energy_area"
      :after="kpiAfter.energy_area"
      :units="'m²'"
    />
    <KpiChangeRow
      v-for="identifier in defaultSustainabilityIndicatorIdentifiers"
      :key="identifier"
      :label="siNames[identifier]"
      :before="kpiBefore[identifier] / 1000"
      :after="kpiAfter[identifier] / 1000"
      :units="bigUnits[identifier]"
      :energy-label-before-ratio="
        identifier === 'pe' ? kpiBefore.pe / kpiBefore.energy_area / kpiBefore.pe_ref : undefined
      "
      :energy-label-after-ratio="identifier === 'pe' ? kpiAfter.pe / kpiAfter.energy_area / kpiAfter.pe_ref : undefined"
    />
    <KpiChangeRow
      :label="$t('kpiRhLabel')"
      :before="kpiBefore.rh_demand / kpiBefore.energy_area"
      :energy-label-before-ratio="kpiBefore.rh_demand / kpiBefore.energy_area / kpiBefore.rh_limit"
      :after="kpiAfter.rh_demand / kpiAfter.energy_area"
      :energy-label-after-ratio="kpiAfter.rh_demand / kpiAfter.energy_area / kpiAfter.rh_limit"
      :units="'kWh/m²'"
    />
    <KpiChangeRow
      :label="$t('kpiDhwDemandLabel')"
      :before="kpiBefore.dhw_demand / kpiBefore.energy_area"
      :after="kpiAfter.dhw_demand / kpiAfter.energy_area"
      :units="'kWh/m²'"
    />
    <KpiChangeRow
      :label="$t('kpiHeatingPowerLabel')"
      :before="kpiBefore.heating_power"
      :after="kpiAfter.heating_power"
      :units="'kW'"
    />
    <KpiChangeRow
      :label="$t('kpiElDemandLabel')"
      :before="kpiBefore.el_demand"
      :after="kpiAfter.el_demand"
      :units="'MWh'"
    />
    <KpiChangeRow
      :label="$t('kpiElProductionLabel')"
      :before="kpiBefore.el_production"
      :after="kpiAfter.el_production"
      :units="'MWh'"
    />
    <!-- <KpiChangeRow :label="$t('kpiElOwnUseLabel')" :before="kpiBefore.el_ownuse / kpiBefore.energy_area" :after="kpiAfter.el_ownuse / kpiAfter.energy_area" :units="'kWh/m²'" /> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import KpiChangeRow from '@/components/building/measures/KpiChangeRow.vue'

export default {
  components: {
    KpiChangeRow,
  },

  props: {
    kpiBefore: {
      type: Object,
    },
    kpiAfter: {
      type: Object,
    },
  },

  computed: {
    ...mapState('portfolio', ['defaultSustainabilityIndicatorIdentifiers']),

    ...mapGetters({
      getEnhancedSustainabilityIndicators: 'portfolio/getEnhancedSustainabilityIndicators',
    }),

    siNames() {
      return this.getEnhancedSustainabilityIndicators().reduce((acc, value) => {
        acc[value.identifier] = value.name
        return acc
      }, {})
    },

    bigUnits() {
      return this.getEnhancedSustainabilityIndicators().reduce((acc, value) => {
        acc[value.identifier] = value.getBigTargetUnit()
        return acc
      }, {})
    },
  },
}
</script>

<style scoped>
.kpi-grid {
  display: grid;
  grid-template-columns: 250px 1fr 1fr auto;
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}

.kpi-grid-header {
  font-weight: 500;
  color: var(--secondary-highlight-color);
}
</style>
