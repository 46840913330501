<i18n>
{
  "de": {
    "enabled": "Ja",
    "disabled": "Nein",
    "noChangeText": "Keine Änderung",
    "zevLabel": "ZEV",
    "zevInfoBoxTitle": "Zusammenschluss zum Eigenverbrauch",
    "zevInfoBox": "Wenn der Zusammenschluss zum Eigenverbrauch ausgewählt ist, wird der PV-Strom auch für den Mieterstrom verwendet. Wenn nicht, wird PV-Strom lediglich für den Allgemeinstrom, Heizung und Warmwasser verwendet.",
    "costsLabel": "Kosten *",
    "autoCostsHint": "Kosten leer lassen für automatische Kostenschätzung"
  }
}
</i18n>

<template>
  <div class="c-edit-electricity-measure">
    <ElectricityGridPicker
      class="energy-grid-picker"
      :edit="edit"
      :clearable="false"
      :carrier-options="carrierOptions"
      :grid-selection-id="gridSelectionId"
      :pv-selection-id="pvSelectionId"
      :grid-default-id="gridDefaultId"
      :pv-default-id="pvDefaultId"
      @gridInput="onGridChange"
      @pvInput="onPvChange"
    />
    <FormRow class="form-row" :label="$t('zevLabel')" :info-title="$t('zevInfoBoxTitle')" :info-text="$t('zevInfoBox')">
      <v-select
        v-if="edit"
        v-model="zevSelection"
        :options="zevOptions"
        :clearable="false"
        :reduce="(t) => t.id"
        @input="onZevInput"
      />
      <div v-else>
        {{ selectedZevLabel }}
      </div>
    </FormRow>
    <FormRow :label="$t('costsLabel')" class="form-row">
      <NumericInput
        v-model="model.costs"
        :edit="edit"
        :min="0"
        optional
        :units="'CHF'"
        class="limit-width"
        @input="onInput"
        @validation="onValidation($event, 'electricity-costs')"
      />
    </FormRow>
    <div class="hint">* {{ $t('autoCostsHint') }}</div>
  </div>
</template>

<script>
import FormRow from '@/components/shared/forms/FormRow.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import ElectricityGridPicker from '@/components/shared/ElectricityGridPicker'
import ComplexFormMixinVue from '@/components/shared/forms/ComplexFormMixin'

export default {
  mixins: [
    //
    ComplexFormMixinVue,
  ],

  components: {
    ElectricityGridPicker,
    FormRow,
    NumericInput,
  },

  props: {
    building: {
      type: Object,
    },
    portfolio: {
      type: Object,
    },
    measure: {
      type: Object,
    },
  },

  data() {
    return {
      zevOptions: [
        { id: 0, label: this.$t('enabled') },
        { id: 1, label: this.$t('disabled') },
        { id: 2, label: this.$t('noChangeText') },
      ],
      zevSelection: 2,
    }
  },

  computed: {
    zevSelectionId() {
      if (this.model !== null && this.model.zev !== null) {
        return this.model.zev ? 0 : 1
      } else {
        return 2
      }
    },
    gridSelectionId() {
      if (this.model !== null) {
        return this.model['grid_id']
      } else {
        return this.carrierOptions[0].id
      }
    },
    pvSelectionId() {
      if (this.model !== null) {
        return this.model['pv_id']
      } else {
        return this.carrierOptions[0].id
      }
    },
    gridDefaultId() {
      return this.measure?.electricity_grid_id
    },
    pvDefaultId() {
      return this.measure?.electricity_pv_id
    },
    carrierOptions() {
      let res = this.portfolio.energy_carriers
        .filter((ec) => ec.electricity_grid)
        .map((ec) => {
          return {
            id: ec.id,
            label: ec.default ? this.$t(`_energyCarriers.${ec.name}`) : ec.name,
            carrier: ec,
          }
        })
      res.push({
        id: -1,
        label: this.$t('noChangeText'),
        carrier: null,
      })
      return res
    },
    selectedZevLabel() {
      let sel = this.zevOptions.find((t) => t.id === this.zevSelection)
      return sel === undefined ? this.$t('noChangeText') : sel.label
    },
  },

  watch: {
    zevSelectionId() {
      this.zevSelection = this.zevSelectionId
    },
  },

  methods: {
    onGridChange(carrierId) {
      this.model.grid_id = carrierId
      this.onInput()
    },

    onPvChange(carrierId) {
      this.model.pv_id = carrierId
      this.onInput()
    },

    onZevInput(id) {
      switch (this.zevSelection) {
        case 0:
          this.model.zev = true
          break

        case 1:
          this.model.zev = false
          break

        default:
          this.model.zev = null
          break
      }
      this.onInput()
    },
  },
}
</script>

<style lang="scss">
.c-edit-electricity-measure {
  & .form-row {
    margin-top: var(--spacing-m);
  }

  & .form-row .v-select {
    width: var(--default-column-width);
  }

  & .limit-width {
    width: 200px;
  }
}
</style>
