<template>
  <div v-if="hasData" class="c-meters-recent-values">
    <div class="values-box" :class="{ hidden: dataType !== 'energy' }">
      <KpiBox
        :label="recentYears[0]?.toString()"
        :value="formatNumberWithThreshold(eeValues.totalValues[0] / 1000, 2, 1, 1)"
        valueUnit="MWh"
        :relativeValue="formatNumberWithThreshold(eeValues.specificValues[0] / 1000, 2, 1, 1)"
        relativeValueUnit="kWh/m²"
        className="energy-kpi"
      />
      <KpiBox
        v-if="!isSingleYear"
        :label="recentYears[1]?.toString()"
        :value="formatNumberWithThreshold(eeValues.totalValues[1] / 1000, 2, 1, 1)"
        valueUnit="MWh"
        :relativeValue="formatNumberWithThreshold(eeValues.specificValues[1] / 1000, 2, 1, 1)"
        relativeValueUnit="kWh/m²"
        className="energy-kpi"
      />
      <div v-if="!isSingleYear" class="difference-box">
        <div :class="eeValues.percentageChange >= 0 ? 'positive' : 'negative'">
          {{ eeValues.percentageChange >= 0 ? '↑' : '↓' }} {{ Math.abs(eeValues.percentageChange) }}%
        </div>
      </div>
    </div>
    <div class="values-box" :class="{ hidden: dataType !== 'emissions' }">
      <KpiBox
        :label="recentYears[0]?.toString()"
        :value="formatNumberWithThreshold(s12eValues.totalValues[0] / 1000, 2, 1, 1)"
        valueUnit="t CO₂e"
        :relativeValue="formatNumberWithThreshold(s12eValues.specificValues[0], 2, 1, 1)"
        relativeValueUnit="kg CO₂e/m²"
        className="emissions-kpi"
      />
      <KpiBox
        v-if="!isSingleYear"
        :label="recentYears[1]?.toString()"
        :value="formatNumberWithThreshold(s12eValues.totalValues[1] / 1000, 2, 1, 1)"
        valueUnit="t CO₂e"
        :relativeValue="formatNumberWithThreshold(s12eValues.specificValues[1], 2, 1, 1)"
        relativeValueUnit="kg CO₂e/m²"
        className="emissions-kpi"
      />
      <div v-if="!isSingleYear" class="difference-box">
        <div :class="s12eValues.percentageChange >= 0 ? 'positive' : 'negative'">
          {{ s12eValues.percentageChange >= 0 ? '↑' : '↓' }} {{ Math.abs(s12eValues.percentageChange) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import KpiBox from '@/components/shared/kpi/KpiBox.vue'

export default {
  components: {
    KpiBox,
  },
  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    projectionsData: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String, // 'energy' or 'emissions'
      required: true,
    },
  },

  data() {
    return {
      eeValues: {},
      s12eValues: {},
    }
  },

  computed: {
    hasData() {
      return !_.isEmpty(this.eeValues.totalValues) && !_.isEmpty(this.s12eValues.totalValues)
    },

    recentYears() {
      const years = this.projectionsData[0].data.years
      return years.length > 1 ? years.slice(-2) : years
    },

    isSingleYear() {
      return this.recentYears.length === 1
    },
  },

  watch: {
    projectionsData: {
      immediate: true,
      handler() {
        this.eeValues = this.getValues('ee')
        this.s12eValues = this.getValues('s12e')
      },
    },
  },

  methods: {
    getValues(kpiType) {
      const data = this.projectionsData.find((data) => data.kpiType === kpiType)?.data || {}
      const totalValues = this.getTotalValues(data)

      return {
        years: this.recentYears,
        totalValues: totalValues,
        specificValues: this.getSpecificValues(data),
        percentageChange: this.getPercentageChange(totalValues),
      }
    },

    getTotalValues(data) {
      const meterTypes = ['END_ENERGY_HEATER', 'ELECTRICITY_TENANTS', 'ELECTRICITY_GENERAL']
      return this.recentYears.map((year) => {
        const yearIndex = data.years.indexOf(year)
        return meterTypes.reduce((total, meterType) => {
          return total + _.get(data, `timeseries.${meterType}.value[${yearIndex}]`, 0)
        }, 0)
      })
    },

    getSpecificValues(data) {
      return this.getTotalValues(data).map((value, index) => {
        const yearIndex = data.years.indexOf(this.recentYears[index])
        const area = data.timeseries.END_ENERGY_HEATER.area[yearIndex]
        return value / area
      })
    },

    getPercentageChange(totalValues) {
      const [previousYear, currentYear] = totalValues

      return _.round(((currentYear - previousYear) / previousYear) * 100, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-meters-recent-values {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eeeeee;
  background-color: #fafafa;

  & .values-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    padding: var(--spacing-xxl) 0;

    &.hidden {
      @media (max-width: 900px) {
        display: none;
      }
    }

    &:first-child {
      border-right: 1px solid #eeeeee;

      @media (max-width: 900px) {
        border-right: none;
      }
    }

    & .difference-box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 var(--spacing-s);

      & div {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: var(--spacing-xxs) var(--spacing-s);
        border-radius: 16px;

        &.negative {
          color: #1a9900;
          background-color: #1a99001a;
        }

        &.positive {
          color: #991a00;
          background-color: #991a001a;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.c-meters-recent-values {
  & .values-box {
    & .c-kpi-box {
      & .value {
        font-size: 24px;
      }
    }
  }
}
</style>
